<template>
  <div>
    <van-notice-bar
      scrollable
      left-icon="volume-o"
      text="想要更好的体验请点击软件下载地址,下载电脑端,功能更多更齐全"
    />
    <van-cell-group>
      <van-cell :title="name" value="" :label="money">
        <van-button size="small" type="primary" to="/buycard"
          >余额充值</van-button
        >
      </van-cell>
      <van-cell
        title="推广链接(返利用户消费10%)"
        icon="user-circle-o"
        v-clipboard:copy="invite"
        v-clipboard:success="onCopy"
        v-clipboard:error="onError"
        v-if="this.$api.IsAgent == false"
      />
      <van-cell
        title="软件下载(含电脑端,手机端,开发文档)"
        icon="fire-o"
        v-clipboard:copy="url"
        v-clipboard:success="onCopys"
        v-clipboard:error="onError"
      />
      <van-cell
      v-if="this.$api.IsAgent == false"
        title="土豆下载地址"
        icon="fire-o"
        v-clipboard:copy="potatourl"
        v-clipboard:success="onCopyss"
        v-clipboard:error="onError"
      />
      <van-cell :title="sup" icon="smile-o" @click="click1" />
      <van-cell :title="group" icon="friends-o" @click="click2" />
      <van-cell title="修改密码" icon="failure" @click="back" />
      <van-cell title="退出登录" icon="exchange" @click="back" />
    </van-cell-group>
  </div>
</template>

<script>
export default {
  name: "info",
  data() {
    return {
      //  下载连接
      url: "http://yezi55.net:90",
      //  推广链接
      invite: "",
      //  用户名
      name: "用户名:",
      //  余额
      money: 0,
      potatourl: "https://note.youdao.com/s/IQEfvn5u",
      sup: "土豆客服:Dove_bot",
      group: "土豆群:yeziyun22",
    };
  },
  methods: {
    onCopy() {
      this.$toast("推广链接:" + this.invite + "\n已复制到剪辑版");
    },
    onCopys() {
      this.$toast("链接:" + this.url + "\n已复制到剪辑版");
    },
    onCopyss() {
      this.$toast("土豆下载地址:" + this.potatourl + "\n已复制到剪辑版");
    },
    onError() {
      this.$toast("复制失败");
    },
    //  退出登录
    back() {
      localStorage.clear();
      this.$toast("退出登录");
      this.$router.replace({ path: "/login" });
    },
    click1() {
      //window.location = "https://pzcp.org/Dove_bot";
    },
    click2() {
      // window.location = "https://pzcp.org/yeziyun666";
    },
  },
  async created() {
    //中介信息设置
    if (this.$api.IsAgent == true) {
      this.sup = localStorage.getItem("agent_support");
      this.group = localStorage.getItem("agent_group");
    }
    //  推广链接
    this.money = "余额：加载中...";
    this.name = "用户名:"+localStorage.getItem("name");
    
    this.invite =
      "http://h5.yezi66.net:90/invite/" + localStorage.getItem("id");
    let ret = await this.$api.fn_get_myinfo();
    if (ret.data.message === "token失效,请重新登录" || ret.data.message === "密码错误") {
      localStorage.clear();
      this.$toast("退出登录");
      this.$router.replace({ path: "/login" });
      return;
    }
    ret = ret.data.data[0];
    const money1 = parseFloat(ret.money) + parseFloat(ret.money_1);
    this.money = "余额：" + money1.toString();
    ret = await this.$api.fn_getdownload();
    ret = ret.data;
    this.url = ret;
  },
};
</script>

<style scoped>
.div1 {
  margin-bottom: 5px;
  margin-left: 14px;
}
.list-content {
  height: 300px;
}
.list-item {
  text-align: center;
  word-wrap: break-word;
  word-break: break-all;
}
.button {
  display: block;
  margin: 0 auto;
  margin-bottom: 2px;
}
.div2 {
  margin-bottom: 5px;
  margin-left: 14px;
  margin-top: 5px;
}
.div3 {
  text-align: center;
}
</style>
